function HeaderSection() {
  return (
    <>
      <h1 id="about">
        Fernanda <span>Carvalho</span>
      </h1>
      <h3>
        311 Alferes Marcilio Machado · Tingui · 82600-140 · +55(41)9874-21876 ·{' '}
        <span>contato@fernanda.dev</span>
      </h3>
    </>
  )
}

export default HeaderSection
