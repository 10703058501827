export const AboutMe: { [key: string]: { description: string } } = {
  pt: {
    description:
      "Olá! Eu sou a Fernanda e tenho vivido uma jornada empolgante como desenvolvedora frontend nos últimos 5 anos. Trabalhar com uma variedade de tecnologias como React, PHP, React Native, Vue.js, JavaScript e TypeScript tem sido uma verdadeira aventura para mim. Meu amor por criar interfaces de usuário fluidas despertou meu interesse no mundo da tecnologia e, desde então, tenho me dedicado a desenvolver experiências digitais que não apenas sejam amigáveis ao usuário, mas também visualmente atraentes. Seja construindo aplicações web responsivas com React ou explorando soluções móveis com React Native, encontro alegria em dar vida a ideias por meio de soluções funcionais e elegantes. Minhas habilidades não se limitam a uma única tecnologia - abraço a oportunidade de trabalhar com diferentes frameworks e linguagens. Desde a natureza dinâmica e interativa do JavaScript até a robustez do TypeScript, explorei diversos ecossistemas, sempre empolgada para aprender e me adaptar ao cenário tecnológico em constante mudança. Com uma base sólida no desenvolvimento frontend, abordo cada projeto com um olhar atento para o design, um compromisso com código limpo e um espírito colaborativo. Sou verdadeiramente apaixonada por contribuir para soluções inovadoras que impactam positivamente as experiências digitais dos usuários.",
  },
  en: {
    description:
      "Hey there! I'm Fernanda, and I've been on an exciting journey as a frontend developer for the past 5 years. Working with a variety of technologies like React, PHP, React Native, Vue.js, JavaScript, and TypeScript has been a real adventure for me. My love for creating seamless user interfaces sparked my interest in the tech world, and since then, I've been dedicated to crafting digital experiences that are not only user-friendly but also visually appealing. Whether it's building responsive web applications with React or diving into mobile solutions with React Native, I find joy in bringing ideas to life with functional and elegant solutions. My skills aren't confined to a single technology – I embrace the opportunity to work with different frameworks and languages. From the dynamic and interactive nature of JavaScript to the robustness of TypeScript, I've explored various ecosystems, always excited to learn and adapt to the ever-changing tech landscape. With a strong foundation in frontend development, I approach every project with a keen eye for design, a dedication to clean code, and a collaborative spirit. I'm genuinely passionate about contributing to innovative solutions that positively impact users' digital experiences.",
  },
};
